<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center align-items-center" style="height:100vh" v-if="loading">
      <span
        class="spinner-border spinner-border-lg"
      ></span>
    </div>
    <div class="row" v-else>
      <div class="row g-2">
        <header class="jumbotron">
        <h2>Liga Terraforming Mars</h2>
        <div class="row">
          <div class="col-md-2">
            <select id="leagueSelect" class="form-select" v-model="dropDownSelect" @change="refreshLeagueInfo">
              <option v-for="j in leaguesList" :key="j._id" :value="j._id">
                {{j.name}} - Temporada {{j.season}}
              </option>
            </select>
          </div>
        </div>
        <template v-if="dropDownSelect === 1">
          <h5>
            Primeira temporada <font-awesome-icon icon="info-circle" style="cursor: pointer" @click="showInfo = !showInfo"/>
          </h5>
          <p class="lead" v-show="showInfo">
            A primeira edição da liga de <strong>Terraforming Mars</strong> será jogada em 4 rondas de 2 jogos cada. <br/>
            Os jogos serão de 4 ou 3 jogadores e o emparelhamento de jogadores será feito automaticamente pelo site (mais fortes contra mais fortes, etc..).<br/> 
            Cada jogo ira ser jogado num mapa random (incluindo <i>fan made</i>), com <i>awards</i> e <i>milestones</i> escolhidas aleatoriamente também. As expansões utilizadas serão <strong>Prelude</strong> + <strong>Venus</strong> (com World Government).<br/>
            Algumas corporações, preludes e cartas foram ligeiramente alteradas com base em estatísticas de centenas de jogos, de forma a tornarem-se mais equilibradas. as alterações podem ser consultadas <a href="https://jeeps.notion.site/Rebalanced-Mars-025fcc726dc645f9aacef08a1486bf4a" target="_blank">aqui</a>.<br/>
            Setup inicial: 3 ou 4 corporações, dois drafts de 5 cartas seguido de draft dos preludes.<br/> 
            Os jogos serão anónimos e apenas no final de cada ronda serão conhecidos os nomes dos jogadores.<br/>
          </p>
        </template>
        <template v-else-if="dropDownSelect === 2">
          <h5>
            Segunda temporada <font-awesome-icon icon="info-circle" style="cursor: pointer" @click="showInfo = !showInfo"/>
          </h5>
          <p class="lead" v-show="showInfo">
            A segunda edição da liga de <strong>Terraforming Mars</strong> será jogada em várias rondas de 2 jogos cada. <br/>
            Os jogos serão de 4 jogadores e o emparelhamento de jogadores será feito automaticamente pelo site (maximizando os confrontos entre jogadores diferentes).<br/> 
            Cada jogo ira ser jogado num mapa random (incluindo <i>fan made</i>), com <i>awards</i> e <i>milestones</i> escolhidas aleatoriamente também. As expansões utilizadas serão <strong>Prelude</strong> + <strong>Venus</strong> (com World Government).<br/>
            Algumas corporações, preludes e cartas foram ligeiramente alteradas com base em estatísticas de centenas de jogos, de forma a tornarem-se mais equilibradas. as alterações podem ser consultadas <a href="https://jeeps.notion.site/Rebalanced-Mars-025fcc726dc645f9aacef08a1486bf4a" target="_blank">aqui</a>.<br/>
            Setup inicial: 3 ou 4 corporações, dois drafts de 5 cartas seguido de draft dos preludes.<br/> 
            Os jogos serão anónimos e apenas no final de cada ronda serão conhecidos os nomes dos jogadores.<br/>
          </p>
        </template>
      </header>
      </div>
      <div class="row g-3">
        <div :class="{'col-md-2': !playersDetail, 'col-md-10': playersDetail}">
          <table class="table table-sm table-stripped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Jogador</th>
                <th v-show="playersDetail">#1</th>
                <th v-show="playersDetail">#2</th>
                <th v-show="playersDetail">#3</th>
                <th v-show="playersDetail">#4</th>
                <th>Pontos&nbsp;&nbsp;&nbsp;&nbsp;<span class="link" @click="showDetails" >
                    <font-awesome-icon icon="angle-double-right" v-if="!playersDetail"/>
                    <font-awesome-icon icon="angle-double-left" v-else/>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(j, index) in sortedLeaguePlayers" :key="index">
                <td>{{index +1}}</td>
                <td>{{j.username}}</td>
                <td v-show="playersDetail">{{j.victories}}</td>
                <td v-show="playersDetail">{{j.seconds}}</td>
                <td v-show="playersDetail">{{j.thirds}}</td>
                <td v-show="playersDetail">{{j.fourths}}</td>
                <td>{{j.points}}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="currentUser && !signedIn && league.status == 'running'">
            <div class="text-left">
              <div class="card-body">
                <button type="button" class="btn btn-sm btn-success" @click="joinLeague">Entrar na proxima ronda</button>
              </div>
            </div>
          </div>
          <div v-if="currentUser && signedIn && league.status == 'running'">
            <div class="text-left">
              <div class="card-body">
                <button type="button" class="btn btn-sm btn-danger" @click="leaveLeague">Sair da liga</button>
              </div>
            </div>         
          </div>
          <div v-if="currentUser && league.status == 'running' && currentUser['roles'].includes('ADMIN')">
            <div class="text-left">
              <div class="card-body">
                <button type="button" class="btn btn-sm btn-success" @click="nextRound">Começar próxima ronda</button>
              </div>
            </div>         
          </div>
        </div>
        <div class="col-md-10">
          <div v-if="currentUser && !signedIn && league.status == 'created'">
            <div class="card text-center">
              <div class="card-body">
                <button type="button" class="btn btn-lg btn-success" @click="joinLeague">Inscrever {{selectedLeague.name}} Temporada {{selectedLeague.season}}</button>
              </div>
            </div>
          </div>
          <div v-if="currentUser && signedIn && league.status == 'created'">
            <div class="card text-center">
              <div class="card-body">
                <p>Já estás inscrito na liga. Ainda não começou.</p>
                <button type="button" class="btn btn-sm btn-danger" @click="leaveLeague">Sair</button>
              </div>
            </div>         
          </div>
          <div v-if="league && league.status != 'created'">
            <div class="games">
              <h3>Jogos a decorrer</h3>
              <table class="table table-sm table-stripped table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th style="min-width: 100px;">Nome</th>  
                    <th>Gen</th>
                    <th>Jog 1</th>
                    <th>Jog 2</th>
                    <th>Jog 3</th>
                    <th>Jog 4</th> 
                  </tr>
                </thead> 
                <tbody>
                  <tr class="clickable" v-for="game in ongoingGames" v-bind:key="game._id" @click="openGame(game)">
                    <td>{{game.name}}</td> 
                    <td>{{game.generation}}</td>
                    <td v-for="player in [1,2,3,4]" v-bind:key="player">
                      <font-awesome-icon icon="user" v-if="game.player_id && game.player_colour == game[`player${player}`].colour" />
                      {{game[`player${player}`].colour}}
                      <span style="font-size: 0.7em;" v-show="game[`player${player}`].points"> ({{game[`player${player}`].points}})</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h3>Jogos terminados </h3>
              <table class="table table-sm table-stripped table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th style="min-width: 100px;">Nome</th>  
                    <th>Gen</th>
                    <th>Jog 1</th>
                    <th>Jog 2</th>
                    <th>Jog 3</th>
                    <th>Jog 4</th> 
                  </tr>
                </thead> 
                <tbody>
                  <tr class="clickable" v-for="game in finishedGames" v-bind:key="game._id" @click="openGame(game)">
                    <td>{{game.name}}</td> 
                    <td>{{game.generation}}</td>
                    <td v-for="player in game.numPlayers" v-bind:key="player">
                      <font-awesome-icon icon="user" v-if="game.player_id && game.player_colour == game[`player${player}`].colour"/>
                      {{game[`player${player}`].name}} 
                      <span style="font-size: 0.7em;" v-show="game[`player${player}`].score"> ({{game[`player${player}`].score}})</span>
                      - 
                      {{game[`player${player}`].colour}}
                      <span style="font-size: 0.7em;" v-show="game[`player${player}`].points"> ({{game[`player${player}`].points}})</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <template v-for="(round,index) in numberOfRounds" v-bind:key="index">
                <button type="button" 
                  @click="selectedRound = round"
                  :class="['btn','btn-sm', selectedRound == round ? 'btn-primary' : 'btn-secondary']">Ronda {{round}}</button>&nbsp;
              </template>
            </div>
          </div>
          
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import LeagueService from "../services/league.service";
import util from '../util';

export default {
  name: "League",
  data() {
    return {
      loading: true,
      leaguesList: [],
      selectedLeague: {},
      league: {},
      showInfo: false,
      playersDetail: false,
      selectedRound: 1,
      maxRounds: 1,
      dropDownSelect: null
    };
  },
  computed: {
    sortedLeaguePlayers() {
      if(this?.league.players)
        return [...this.league.players].sort((a,b) => { return b.points - a.points; });
      else 
        return [];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    signedIn (){
      return this.currentUser && this.league && this.league.players && this.league.players.find(p => p.user_id == this.currentUser.user_id);
    },
    ongoingGames(){
      if(this.league?.games)
        return this.league.games.filter(g => g.status != 'end').sort((a,b) => a.name < b.name);
      else 
        return [];
    },
    finishedGames(){
      if(this.league?.games)
        return this.league.games.filter(g => g.round_id == this.selectedRound && g.status == 'end');
      else 
        return [];
    },
    numberOfRounds(){
      return Array.from({length: this.maxRounds}, (_, i) => i + 1)
    }
  },
  created() {
    this.refreshPage();
  },
  methods: {
    async refreshPage() {
      if(this.selectedLeague._id){
        this.loading = true;
        this.dropDownSelect = this.selectedLeague._id;
        await this.refreshLeagueInfo();
      } else {
        let allLeagues = await LeagueService.getAllLeagues();
        this.leaguesList = allLeagues.data.filter(x => x.name == "Liga TM");
        //by default the selected is either the created one or the running one (only 1 of those at any time)
        
        this.selectedLeague = this.leaguesList.find(x => x.status == 'created' || x.status == 'running');
        if(!this.selectedLeague) 
        {
          var league = Math.max(...this.leaguesList.map(o => o._id))
          this.selectedLeague = this.leaguesList.find(x => x._id == league);
        
        }
        this.dropDownSelect = this.selectedLeague._id;
        await this.refreshLeagueInfo();
      }      
    },
    async refreshLeagueInfo(){
      let response = await LeagueService.getLeagueInfo(this.dropDownSelect)
      this.league = response.data;
      this.loading = false;

      this.maxRounds = this.league.games.reduce((prev, curr) => {
        return curr.round_id > prev ? curr.round_id : prev;
      }, 1);
      this.selectedRound = this.maxRounds;
    },
    showDetails() {
      this.playersDetail = !this.playersDetail;
    },
    joinLeague(){
      LeagueService.joinCurrentLeague(this.league._id).then(() => this.refreshPage());
    },
    leaveLeague(){
      LeagueService.leaveCurrentLeague(this.league._id).then(() => this.refreshPage());
    },
    nextRound(){
      LeagueService.nextLeagueRound(this.league._id).then(() => this.refreshPage());
    },
    winner(game, player){
      let points;
      [1,2,3,4].forEach(p => {
        if(!points && game[`player${player}`].user_id == game[`player${p}`].user_id && game.status == 'end')
          points = game[`player${p}`].points;
      });
      return false;
    },
    openGame(game){ 
      let url = game.player_id ? `/player?id=${game.player_id}` : `/spectator?id=${game.spectator}`;
      let g_url = new URL(game.url); 
      window.open(g_url.origin + url, '_blank');
    },
    deltaToNow: util.deltaToNow,
  }
};
</script>

<style scoped>
  .table{
    font-size: 12px
  } 

  .highlight {
    font-weight: bold;
  }  
  
  .clickable {
    cursor: pointer;
  }

  .row-active {
    background-color: rgb(175, 224, 175);
  }

  .row-active:hover {
    background-color: rgb(24, 110, 24);
  }

  .link {
    color: #0d6efd;
    cursor: pointer;
  }
</style>